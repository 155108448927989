if (document.querySelectorAll(".swiper-opinion").length > 0) {
    const swiper = new Swiper('.swiper-opinion', {
        direction: 'horizontal',
        effect: "fade",
        preloadImages: false,
        lazy: true,
        autoplay: {
            delay: 8000,
            speed: 1000,
            disableOnInteraction: false,
        },
        loop: true,
        preventInteractionOnTransition: true,
        pagination: {
            el: ".swiper-opinion .swiper-pagination",
            type: "fraction",
        },
        navigation: {
            nextEl: '.swiper-opinion .swiper-button-next',
            prevEl: '.swiper-opinion .swiper-button-prev',
        },
        slideActiveClass: 'swiper-slide-active',
    });

    swiper.on('slideResetTransitionEnd', function(swiper, transition) {
        document.querySelector(".swiper-opinion .swiper-slide-active").classList.remove("swiper-slide-active");

    });

}
if (document.querySelectorAll(".method").length > 0) {
    const swiper4 = new Swiper('.swiper-method .swiper', {
        direction: 'horizontal',
        // effect: "creative",
        // creativeEffect: {
        //   prev: {
        //     shadow: true,
        //     translate: [0, 0, -400],
        //   },
        //   next: {
        //     translate: ["100%", 0, 0],
        //   },   
        // },
        preloadImages: false,
        lazy: true,

        autoplay: {
            delay: 6000,
            speed:6000,
        },
        loop: true,
        pagination: {
            el: ".swiper-method .swiper-pagination",  
            clickable: "true"    ,    
        },

        navigation: {
            nextEl: '.swiper-method .swiper-button-next',
            prevEl: '.swiper-method .swiper-button-prev',
        },

        slideActiveClass: 'swiper-slide-active',

    });
    swiper4.on('slideResetTransitionEnd', function(swiper, transition) {
        document.querySelector(".swiper-recommended .swiper-slide-active").classList.remove("swiper-slide-active");

    });
}
